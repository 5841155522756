
(function($)
{
	"use strict";

	$(document).ready(function()
	{
		/**
		 * Voting
		 */
		var $votebuttons = $('.layout_full a.vote-button, .layout_voting a.vote-button');
		$votebuttons.each(function()
		{
			var $a = $(this);

			$a.click(function(e)
			{
				e.preventDefault();

				if ($a.hasClass('disabled'))
				{
					return false;
				}

				$a.addClass('disabled');

				$.get($a.attr('href'), function(response)
				{
					if (!response.voted)
					{
						$a.removeClass('disabled');
					}

					if (response.votesleft == 0)
					{
						$votebuttons.addClass('disabled');
					}

					$('#header .remaining-votes .num').text(response.votesleft);

					$('.layout_full p.vote-count').each(function()
					{
						$(this).text(response.newsvotes + ' ' + (response.newsvotes == 1 ? 'Vote' : 'Votes'));
					});

				}).failure(function()
				{
					$a.removeClass('disabled');
				});

				return false;
			});
		});


		/**
		 * Slide to default category and slide to category on click
		 */
		setTimeout(function()
		{
			var $categoryslider = $('#startup-category-slider');
			var $categorynav    = $('#startup-category-list');

			if ($categoryslider.length > 0 && $categorynav.length > 0)
			{
				var activeIndex = $categorynav.find('li.active').index();
				var swiper = $categoryslider.find('.swiper-container').data('swiper');
				swiper.slideTo(activeIndex, 0);
				$categoryslider.find('.swiper-pagination-fraction').append(' <span class="category">'+$categorynav.find('li.active').text()+'</span>');

				swiper.on('slideChangeStart', function()
				{
					console.log(swiper.activeIndex);
					$categorynav.find('li,a').removeClass('active');
					var text = $categorynav.find('li').eq(swiper.activeIndex).addClass('active').text();

					$categoryslider.find('.swiper-pagination-fraction .category').text(text);
				});

				$categorynav.find('li').each(function(index, element)
				{
					var $li = $(this);
					$li.find('a').click(function()
					{
						swiper.slideTo($li.index());
						return false;
					});
				});
			}
		}, 0);
	});

})(jQuery);
